// Styles
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import './scss/style.scss';

.col-divider {
  width: 1px;
  height: 40px;
  background-color: #d2d7db;
  display: block;
  overflow: hidden;
  margin: 0px 12px;
}

.app-searchbar {
  width: calc(100% - 300px);
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  z-index: 998;
  /*pointer-events: none;*/
  background-color: rgba(76, 76, 76, 0.61);
  background: rgba(76, 76, 76, 0.61);
  background: -moz-linear-gradient(top, rgba(76, 76, 76, 0.61) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(76, 76, 76, 0.61)), color-stop(100%, rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(top, rgba(76, 76, 76, 0.61) 0%, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(top, rgba(76, 76, 76, 0.61) 0%, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(top, rgba(76, 76, 76, 0.61) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(76, 76, 76, 0.61) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4c4c4c', endColorstr='#000000', GradientType=0);
}

.app-searchbar.is-open {
  width: 100%;
}

.app-searchbar-content {
  width: calc(100% - 60px);
  pointer-events: auto;
  background-color: #fff;
  border-radius: 4px;
  padding: 7px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.07);
  margin-top: 10px;
}

.app-searchbar-container {
  display: 'flex';
}

.app-logobar {
  width: 140px;
  height: 67px;
  position: absolute;
  bottom: 0px;
  left: 5px;
  pointer-events: none;
}

.app-sidebar {
  /* position: fixed;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 100vh;
  z-index: 1001;
  background-color: #fff;
  box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.07);
  display: none; */

  position: fixed;
  top: 0px;
  right: -300px;
  /* Inicialmente fora da tela */
  width: 300px;
  height: 100vh;
  z-index: 1001;
  background-color: #fff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  opacity: 0;
  /* Inicialmente invisível */
  transition: transform 0.8s ease, opacity 0.8s ease;
}

.app-sidebar.is-open {
  /* display: flex;
  flex-direction: column; */

  transform: translateX(-300px);
  /* Move o sidebar para a posição final */
  opacity: 1;
  /* Torna o sidebar visível */

}

.app-sidebar-header {
  border-bottom: 1px solid #d2d7db;
  height: 120px;
}

.app-sidebar-body {
  height: 100%;
  flex-grow: 2;
  overflow-y: auto;
}

.app-sidebar-header-title {
  color: #0b89ee;
  margin: 15px;
}

.pin-task {
  width: 100%;
  min-height: 60px;
  border-left: 3px solid #0084ee;
  background-color: #e5f3fd;
  border-bottom: 1px solid #fff;
  padding: 16px 7px;
  color: #686868;
  cursor: pointer;
}

.pin-task-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.pin-task.red {
  background-color: #feeaea;
  border-left-color: #f73131;
}

.pin-task.green {
  background-color: #e5f4e5;
  border-left-color: #009000;
}

.pin-task.orange {
  background-color: #fff1e5;
  border-left-color: #ff7200;
}

.pin-task.gray {
  background-color: #f0f0f0;
  border-left-color: #686868;
}

/** Hover **/
.pin-task.blue:hover {
  background-color: darken(#e5f3fd, 5%);
  border-left-color: darken(#0084ee, 5%);
}

.pin-task.red:hover {
  background-color: darken(#feeaea, 5%);
  border-left-color: darken(#f73131, 5%);
}

.pin-task.green:hover {
  background-color: darken(#e5f4e5, 5%);
  border-left-color: darken(#009000, 5%);
}

.pin-task.orange:hover {
  background-color: darken(#fff1e5, 5%);
  border-left-color: darken(#ff7200, 5%);
}

.pin-task.gray:hover {
  background-color: darken(#f0f0f0, 5%);
  border-left-color: darken(#686868, 5%);
}

.app-check-item-name.checked {
  text-decoration: line-through;
}

.app-sidebar-list {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.app-sidebar-list-body {
  display: flex;
  flex-grow: 2;
}

.task-list-content {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.user-bar {
  background-color: #0b89ee;
  color: #fff;
  padding: 7px 15px;

  .user-bar-avatar {
    border: 3px solid #fff;
    margin-right: 7px;
  }

  .user-bar-menu>li a {
    color: #fff;
    text-decoration: none;
    list-style: none;
  }
}


/* Carousel */

.esl-carousel {
  width: 100%;
  border: 1px dashed #CCC;
}

.esl-carousel-content {
  display: flex;
  width: 100%;
}

.esl-carousel-prev,
.esl-carousel-next {
  width: 40px;
  background: none;
  flex-grow: 0;
  border: none;
  position: relative;
  z-index: 8;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;
}

.esl-carousel-prev:focus,
.esl-carousel-next:focus {
  outline: 0;
}

.esl-carousel-prev:hover {
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 8%, rgba(0, 0, 0, 0) 100%);
}

.esl-carousel-next:hover {
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 92%);
}

.esl-carousel-inner {
  width: 100%;
  position: relative;
  margin-left: -40px;
  z-index: 3;
}

.esl-carousel-inner-image {
  width: calc(100% - 8px);
  flex-grow: 2;
  margin: 4px;
}

.esl-searchbar-advanced {
  margin-left: 30px;
  left: 0px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8' opacity='0.6'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8' opacity='0.6'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !important;
}

ol.carousel-indicators li {
  background-color: #000000 !important;
  opacity: 0.4;
}

ol.carousel-indicators li.active {
  opacity: 0.7;
}